import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { redirectTo } from '../../helpers/urls';
import { BlockInfoLicense } from '../blocks/BlockInfoLicense';
import { BlockInvoices } from '../blocks/BlockInvoices';
import { BlockNotifications } from '../blocks/BlockNotifications';
import { BlockTitle } from '../blocks/BlockTitle';
import { SVG_back } from '../elements/SVG';

export const ScreenInfo = () => {

    const { environment, mainReducer } = useContext( MainContext );

    const handleGoToPremium = () => {
        redirectTo(environment,'premium');
    }

    return  <>
            <section className="e__plns__lst__main">
                <BlockTitle title="plan" />
                <BlockNotifications />
                <div className="e__plns__lst__hd">
                    <div className="e__plns__hdg__wrp">
                        <div className='e__plns__hdg__wrp__lst'>
                            {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense <= mainReducer.state.currentLicense.id)) &&
                                <>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_asignado') }:</div>
                                        <BlockInfoLicense license={ { id: mainReducer.state.subLicense } } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                </>
                            }
                            {((mainReducer.state.inProgressLicense.id == mainReducer.state.currentLicense.id) && (mainReducer.state.inProgressLicense.periodicity == mainReducer.state.currentLicense.periodicity) && (mainReducer.state.inProgressLicense.licenses == mainReducer.state.currentLicense.licenses)) ?
                                <>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_contratado') }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.currentLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                </>
                            :
                                <>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_contratado') }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.inProgressLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('a_partir_de') } { tsToDate( mainReducer.state.inProgressLicense.expiration, environment.lang ) }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.currentLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                </>
                            }
                            {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense > mainReducer.state.currentLicense.id)) &&
                                <>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_asignado') }:</div>
                                        <BlockInfoLicense license={ { id: mainReducer.state.subLicense } } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <button className="e__btn e__btn__prmry" onClick={ handleGoToPremium }>{ getText('mejorar_plan') }</button>
            </section>
            <BlockInvoices />
            </>
    
}