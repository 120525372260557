import { getText } from '../../helpers/language';
import { SVG_star } from '../elements/SVG';
import config from '../../resources/config.json';

export const BlockInfoLicense = ({ license }) => {

    return  <>
            <div className={`e__plns__hdg__hd e__plns__hdg__hd--${license.id}${(config.plans[license.id].highlight) ? ' e__plns__hdg__hd--highlight' : ''}`}>
                { (license.id > 0) &&
                    <span className="e__plns__hdg__hd__ico"><SVG_star /></span>
                }
                <div className="e__plns__hdg__hd__main">
                    <div className="e__plns__hdg__hd__shdg">{ getText('plan_educaplay') }</div>
                    <div className="e__plns__hdg__hd__wrp">
                        <span className="e__plns__hdg__hd__hdg">
                            { getText('plan_name_' + license.id) }
                        </span>
                        <span className="e__plns__hdg__hd__lcns">{ ((license.id > 0) && (license.periodicity !== undefined)) ? ' ' + getText('periodicity_' + license.periodicity) : '' }</span>
                        <span className="e__plns__hdg__hd__perid">{ (license.licenses > 1) ? ' (' + license.licenses + ' ' + getText('licencias') + ')' : '' }</span>
                    </div>
                </div>
            </div>
            </>;
}