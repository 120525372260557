import { useContext } from 'react';
import { getText } from '../../helpers/language';
import { MainContext } from '../../contexts/main';

export const BlockBillingAddress = () => {

    const { mainReducer } = useContext( MainContext );

    const generateLastLineAddress = () => {
        let lastLineAddress = mainReducer.state.selectedLicense.zip_code;
        if (lastLineAddress != '') {
            lastLineAddress += ' - ';
        }
        lastLineAddress += mainReducer.state.selectedLicense.town;
        if (lastLineAddress != '') {
            lastLineAddress += ' - ';
        }
        lastLineAddress += mainReducer.state.selectedLicense.province;
        if (lastLineAddress != '') {
            lastLineAddress += ' ';
        }
        lastLineAddress += '(' + mainReducer.state.selectedLicense.country + ')';
        return lastLineAddress;
    }

    return  <>
                <legend>
                    { getText('datos_de_facturacion') }
                </legend>
                <address>
                    { (mainReducer.state.selectedLicense.name != '') && <>{ mainReducer.state.selectedLicense.name }<br /></> }
                    { (mainReducer.state.selectedLicense.vat_number != '') && <>{ mainReducer.state.selectedLicense.vat_number }<br /></> }
                    { (mainReducer.state.selectedLicense.address != '') && <>{ mainReducer.state.selectedLicense.address }<br /></> }
                    { generateLastLineAddress() }
                </address>
            </>;
}