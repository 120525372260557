import { useRef, useEffect } from "react";

const useOutsideAlerter = (ref,action,scope) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && (!ref.current.contains(event.target) || scope == 'full')) {
                action();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {     
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const OutsideAlerter = ({ action, scope, children }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef,action,scope);
    return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideAlerter;