import { SVG_loading } from '../elements/SVG';
export const BlockLoading = () => {

    
    return  <>
            <div className="crea__loading crea__loading--mini">
                <span className="crea__loading__simbolo">
                    <SVG_loading />          
                </span>
            </div>
            </>;
}