import { getText } from '../../helpers/language';

export const InputUpDown = ({ field, value, handleSave, min, max }) => {

    const handleOnChange = ({ target }) => {
        let localValue = parseInt(target.value);
        if (isNaN(localValue)) {
            localValue = 0;
        }
        if ((min !== undefined) && (localValue < min)) {
            localValue = min;
        }
        if ((max !== undefined) && (localValue > max)) {
            localValue = max;
        }
        if (localValue != value) {
            handleSave( localValue );
        }
    }

    const increase = () => {
        const sent = {
            target: {
                value: value + 1
            }
        };
        handleOnChange( sent );
    }

    const decrease = () => {
        const sent = {
            target: {
                value: value - 1
            }
        };
        handleOnChange( sent );
    }

    const handleStartEdit = ({ target }) => {
        target.select();
    }

    return  <>
            {(field !== undefined) &&
                <label className="e__count__hd" htmlFor={`field_${field}`}>{ getText('field_' + field + '_title') }</label>
            }
            <div className="e__count__wpr">
                <button onClick={ decrease } className="e__count__minus">-</button>
                <input id={(field !== undefined) ? `field_${field}` : ''} name="Lite license number" type="number" value={ value } onChange={ handleOnChange } onFocus={ handleStartEdit } />
                <button onClick={ increase } className="e__count__plus">+</button>
            </div>
            </>;

}
