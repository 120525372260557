import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { isLicenseActivatedNow } from '../../helpers/billing';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { BlockBillingAddress } from '../blocks/BlockBillingAddress';
import { BlockInfoLicense } from '../blocks/BlockInfoLicense';
import { BlockNotifications } from '../blocks/BlockNotifications';
import { BlockPayment } from '../blocks/BlockPayment';
import { SVG_back } from '../elements/SVG';
import { NoticeDataPending } from '../notices/NoticeDataPending';
import config from '../../resources/config.json';
import { BlockTitle } from '../blocks/BlockTitle';

export const ScreenDataPending = ({ type }) => {

    const { mainReducer, appReducer, environment } = useContext( MainContext );

    const [ stateExpanded, setStateExpanded ] = useState(false);
    const [ statePaymentMethod, setStatePaymentMethod ] = useState( false );
    const [ stateBillingData, setStateBillingData ] = useState( false );

    useEffect( () => {
        appReducer.dispatch({
            type: 'setNotifications',
            payload: [
                {
                    type: 'send',
                    content: <NoticeDataPending />
                }
            ]
        });
    }, []);

    useEffect( () => {
        if (mainReducer.state.selectedPaymentMethod == mainReducer.state.currentPaymentMethod.id) {
            setStatePaymentMethod(false);
        } else {
            for (const idPaymentMethod in mainReducer.state.paymentMethods) {
                if (mainReducer.state.paymentMethods[idPaymentMethod].id == mainReducer.state.selectedPaymentMethod) {
                    setStatePaymentMethod(mainReducer.state.paymentMethods[idPaymentMethod])
                    break;
                }
            }
        }
    }, [ mainReducer.state.selectedPaymentMethod, mainReducer.state.paymentMethods, mainReducer.state.currentPaymentMethod ]);

    useEffect( () => {
        if ((mainReducer.state.currentLicense.wantsAnInvoice != mainReducer.state.selectedLicense.wantsAnInvoice) ||
        (mainReducer.state.currentLicense.name != mainReducer.state.selectedLicense.name) ||
        (mainReducer.state.currentLicense.vat_number != mainReducer.state.selectedLicense.vat_number) ||
        (mainReducer.state.currentLicense.address != mainReducer.state.selectedLicense.address) ||
        (mainReducer.state.currentLicense.zip_code != mainReducer.state.selectedLicense.zip_code) ||
        (mainReducer.state.currentLicense.town != mainReducer.state.selectedLicense.town) ||
        (mainReducer.state.currentLicense.province != mainReducer.state.selectedLicense.province) ||
        (mainReducer.state.currentLicense.name != mainReducer.state.selectedLicense.name) ||
        (mainReducer.state.currentLicense.country != mainReducer.state.selectedLicense.country)) {
            setStateBillingData(true);
        } else {
            setStateBillingData(false)
        }
    }, [ mainReducer.state.currentLicense, mainReducer.state.selectedLicense ]);

    const handleSeeChange = () => {
        setStateExpanded( true );
    }
    
    return  <>
            <div className="e__plns">
                <BlockTitle title={ 'envio_de_confirmación_de_cambio' } />
                <BlockNotifications />
                <div className="e__plns__lst">
                    <div className="e__plns__lst__hd">
                        <div className={`e__plns__lst__hd__item e__plns__lst__hd__item--${mainReducer.state.inProgressLicense.id}${(config.plans[mainReducer.state.inProgressLicense.id].highlight) ? ' e__plns__lst__hd__item--highlight' : ''}`}>
                            <div className="e__plns__lst__hd__hdg">{ getText('plan_contratado') }:</div>
                            <BlockInfoLicense license={ mainReducer.state.inProgressLicense } />
                            <span className="e__plns__lst__hd__ico">
                                <SVG_back />
                            </span>
                        </div>
                        { (type == 'cancel') ?
                            <div className="e__plns__lst__hd__item e__plns__lst__hd__item--0">
                                <div className="e__plns__lst__hd__hdg">{ getText('nuevo_plan') }:</div>
                                <BlockInfoLicense license={{
                                    id: 0,
                                    licenses: 1
                                 }} />
                            </div>
                        :
                            <div className={`e__plns__lst__hd__item e__plns__lst__hd__item--${mainReducer.state.selectedLicense.id}${(config.plans[mainReducer.state.selectedLicense.id].highlight) ? ' e__plns__lst__hd__item--highlight' : ''}`}>
                                { (isLicenseActivatedNow( mainReducer )) ?
                                    <div className="e__plns__lst__hd__hdg">{ getText('nuevo_plan') }:</div>
                                :
                                    <div className="e__plns__lst__hd__hdg">{ getText('a_partir_de') } { tsToDate( mainReducer.state.inProgressLicense.expiration, environment.lang ) }:</div>
                                }
                                <BlockInfoLicense license={ mainReducer.state.selectedLicense } />
                                {(
                                    (statePaymentMethod !== false) ||
                                    (stateBillingData !== false)
                                ) &&
                                    <div className="e__plns__hdg__hd__new">
                                        <div className="e__plns__hdg__hd__new__info">
                                            { getText('se_ha_realizado_un_cambio_plan') }
                                        </div>
                                        { (stateExpanded) ?
                                            <div className="e__plns__hdg__hd__new__wrp">
                                                { (stateBillingData !== false) && 
                                                    <fieldset className="e__addrs">
                                                        {(mainReducer.state.selectedLicense.wantsAnInvoice === false) ?
                                                            <>
                                                                <legend>
                                                                    { getText('datos_de_facturacion') }
                                                                    <span className="e__btn__info" >
                                                                        <button className="e__btn" aria-label={ getText('mas_informacion') } title={ getText('mas_informacion') }>i</button>
                                                                        <span className="e__btn__info__wp">{ getText('datos_de_facturacion_txt') }</span>
                                                                    </span>
                                                                </legend>
                                                                <div>
                                                                    { getText('sin_datos_de_facturacion') }
                                                                </div>
                                                            </>
                                                        :
                                                            <BlockBillingAddress />
                                                        }
                                                    </fieldset>
                                                }
                                                { (statePaymentMethod !== false) && 
                                                    <div className="e__plns__opt__pay__wpr">
                                                        <div className="e__plns__opt__pay__wpr__save">
                                                            <BlockPayment payment={ statePaymentMethod } />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        :
                                            <button onClick={ handleSeeChange } className="e__lnk">{ getText('ver_cambio') }</button>
                                        }
                                    </div>
                                }
                                <span className="e__plns__lst__hd__ico">
                                    <SVG_back />
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </>
    
}