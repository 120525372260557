import { useContext, useLayoutEffect, useRef } from 'react';
import { MainContext } from '../../contexts/main';
import { isEmail } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { BlockNotifications } from '../blocks/BlockNotifications';
import { BlockTitle } from '../blocks/BlockTitle';
import { InputText } from '../inputs/InputText';

export const ScreenIdentify = () => {

    const { mainReducer, appReducer } = useContext( MainContext );

    const input = useRef('');
    const inputTextBox = useRef(null);
    
    useLayoutEffect( () => {
        inputTextBox.current.focus();
    }, []);

    const handleIdentify = () => {
        if (isEmail(input.current)) {
            appReducer.dispatch({
                type: 'clearFieldErrors',
            });
            mainReducer.dispatch({
                type: 'setPayerEmail',
                payload: input.current
            });
        } else {
            appReducer.dispatch({
                type: 'setFieldErrors',
                payload: [
                    {
                        section: 'identify',
                        field: 'email',
                        error: 'wrong_email'
                    }
                ]
            }); 
        }
    }

    const handleSaveEmail = ( value ) => {
        input.current = value;
    }

    return  <>
                <BlockTitle title="introduce_tu_email" />
                <BlockNotifications />
                <div className="e__mail">
                    <InputText ref={ inputTextBox } handleOnEnterPress={ handleIdentify } field="email" section="identify" handleSave={ handleSaveEmail } type="email" />
                    <div className="e__txt">
                        <span className="e__txt__info">{ getText('identify_txt') }</span>
                    </div>
                    <button onClick={ handleIdentify } className="e__btn e__btn__scndry">{ getText('aceptar') }</button>
                </div>
            </>;
    
}