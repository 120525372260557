import { createContext, useReducer, useMemo } from 'react';
import { getEnvironment } from '../helpers/environment';
import { appReducer, getAppInitialState } from '../reducers/app';
import { mainReducer, getMainInitialState } from '../reducers/main';

export const getContext = () => {
    const environment = useMemo(() => getEnvironment(),[]);
    const mainInitialState = useMemo(() => getMainInitialState(),[]);
    const appInitialState = useMemo(() => getAppInitialState(),[]);
    
    const [ mainState, mainDispatch ] = useReducer(mainReducer, mainInitialState);
    const [ appState, appDispatch ] = useReducer(appReducer, appInitialState);
    
    const contexto = {
        environment: environment,
        mainReducer: {
            state: mainState,
            dispatch: mainDispatch
        },
        appReducer: {
            state: appState,
            dispatch: appDispatch
        }
    }
    return contexto;
}

export const MainContext = createContext(null);