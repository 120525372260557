import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { BlockNotification } from './BlockNotification';

export const BlockNotifications = () => {

    const { appReducer } = useContext( MainContext );

    return  <>
                <div className='e__plns__lst__notice'>
                    {appReducer.state.notifications.map( (notification, index) =>
                        <BlockNotification notification={ notification } key={ index } />
                    )}
                </div>
            </>;
}