import config from '../resources/config.json';
import { equalObjects } from './functions';

export const getVolumeDiscount = ( licenses, discountRange ) => {
    let start_discount = 0;
    let end_discount = 0;
    let start_section = 1;
    let end_section = 0;
    let first = true;
    let doBreak = false;
    let previous_discount = 0;
    let discount = 0;
    discountRange.forEach( section => {
        if (!doBreak) {
            if ((section.start <= licenses) && (section.end >= licenses)) {
                if (!first) {
                    start_section = section.start;
                    start_discount = previous_discount;
                }
                end_section = section.end;
                end_discount = section.percent;
            }
            previous_discount = section.percent;
            if (end_section != 0) {
                doBreak = true;
            }
            first = false;
        }
    });
    if ((end_section - start_section + 1) == 0) {
        discount = 0;
    } else {
        let percentage = (licenses - start_section + 1) * 100 / (end_section - start_section + 1);
        discount = ((end_discount - start_discount) * percentage / 100) + start_discount;
        discount = Math.round(discount * 100) / 100;
    }
    return discount;
}

export const getRates = ( selectedLicense, inProgressLicense , rates, discountApplied, decimals, credit, minimumAmount, budget ) => {
    let response = {}
    Object.keys(config.plans).forEach( idLicense => {
        const mSelectedLicense = {
            ...selectedLicense,
            id: idLicense
        }
        if (idLicense != 0) {
            const perUserAndMonth = getRatePerUserAndMonth( mSelectedLicense, rates, discountApplied, decimals );
            const totalPeriodic = getRateTotalPeriodic( mSelectedLicense, perUserAndMonth );
            let totalPeriodicApplied;
            if ((budget !== false) && (budget.amount !== false) && (budget.license == mSelectedLicense.id) && (budget.periodicity == mSelectedLicense.periodicity) && (budget.licenses == mSelectedLicense.licenses)) {
                totalPeriodicApplied = budget.amount;
            } else {
                totalPeriodicApplied = totalPeriodic;
            }
            const totalNow = getTotalNow( mSelectedLicense, inProgressLicense, totalPeriodicApplied, credit, minimumAmount );
            response[idLicense] = {
                perUserAndMonth: perUserAndMonth,
                totalNow: applyVAT(totalNow, selectedLicense.vat, decimals),
                totalPeriodic: applyVAT(totalPeriodic, selectedLicense.vat, decimals),
                totalPeriodicWithoutVAT: totalPeriodic
            }
        }
    });
    return response;
}

const getRatePerUserAndMonth = ( selectedLicense, rates, discountApplied, decimals ) => {
    const roundMultiplier = Math.pow(10,decimals);
    let perUserAndMonth = rates[selectedLicense.id][selectedLicense.periodicity];
    perUserAndMonth = perUserAndMonth * ((100 - discountApplied) / 100);
    perUserAndMonth = Math.round(perUserAndMonth * roundMultiplier) / roundMultiplier;
    return perUserAndMonth;
}

const getRateTotalPeriodic = ( selectedLicense, perUserAndMonth ) => {   
    const months = config.periodicities[selectedLicense.periodicity].months
    const totalPeriodic = perUserAndMonth * months * selectedLicense.licenses;
    return totalPeriodic;
}

const applyVAT = ( amount, vat, decimals ) => {
    const roundMultiplier = Math.pow(10,decimals);
    amount = amount * ((100 + vat) / 100);
    amount = Math.round(amount * roundMultiplier) / roundMultiplier;
    return amount;
}

const getTotalNow = ( selectedLicense, inProgressLicense, totalPeriodic, credit, minimumAmount ) => {
    let totalNow = totalPeriodic;
    let activateNow = false;
    if (selectedLicense.id > inProgressLicense.id) {
        activateNow = true;
    } else if (selectedLicense.id == inProgressLicense.id) {
        if (selectedLicense.licenses > inProgressLicense.licenses) {
            activateNow = true;
        } else {
            totalNow = 0;
        }
    } else {
        totalNow = 0;
    }
    if (activateNow) {
        totalNow -= credit;
    }
    if (totalNow < minimumAmount) {
        totalNow = 0;
    }
    return totalNow;
}

export const getDiscountForPeriodicities = ( rates ) => {
    const discounts = {};
    Object.keys(rates).forEach( idLicense => {
        let maxRate = 0;
        discounts[idLicense] = {};
        Object.keys(rates[idLicense]).forEach( periodicity => {
            if (rates[idLicense][periodicity] > maxRate) {
                maxRate = rates[idLicense][periodicity];
            }
        });
        Object.keys(rates[idLicense]).forEach( periodicity => {           
            let discount = (maxRate - rates[idLicense][periodicity]) * 100 / maxRate;
            discount = Math.floor(discount / 5) * 5;            
            discounts[idLicense][periodicity] = discount;
        });
    });
    return discounts;  
}

export const isPayable = ( mainReducer ) => {
    const currentPaymentMethod = (mainReducer.state.currentPaymentMethod.id === undefined) ? false : mainReducer.state.currentPaymentMethod.id;
    const selectedLicense = {...mainReducer.state.selectedLicense};
    const currentLicense = {...mainReducer.state.currentLicense};
    delete selectedLicense.payer_email;
    delete currentLicense.payer_email;
    if ((equalObjects(selectedLicense,currentLicense)) && ((mainReducer.state.newPaymentMethod === false) || (mainReducer.state.newPaymentMethod.type === false)) && (currentPaymentMethod === mainReducer.state.selectedPaymentMethod)) {
        return false;
    } else {
        return true;
    }
}

export const isLicenseActivatedNow = ( mainReducer ) => {
    let activateNow = false;
    if (mainReducer.state.selectedLicense.id > mainReducer.state.inProgressLicense.id) {
        activateNow = true;
    } else if (mainReducer.state.selectedLicense.id == mainReducer.state.inProgressLicense.id) {
        if (mainReducer.state.selectedLicense.licenses > mainReducer.state.inProgressLicense.licenses) {
            activateNow = true;
        }
    }
    return activateNow;
}