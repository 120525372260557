import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { SVG_user } from '../elements/SVG';

export const BlockViewUser = () => {

    const { mainReducer } = useContext( MainContext );

    return  <>
            { (mainReducer.state.user !== false) &&
                <fieldset className="e__addrs e__addrs--user">
                    <legend>
                        { getText('usuario') }
                    </legend>
                    <div className="e__addrs__wpr">
                        <span className="e__addrs__ico">
                            <SVG_user />
                        </span>
                        <div className="e__addrs__info">
                            <span className="e__addrs__name">{ mainReducer.state.user.name }</span>
                            <span className="e__addrs__mail">{ mainReducer.state.user.email }</span>
                        </div>
                    </div>
                </fieldset>
            }
            </>;
}