import { useLayoutEffect, useRef } from 'react';

export const PostRedirecter = ({ data }) => {

    const form = useRef(false);

    useLayoutEffect( () => {
        if (data !== false) {
            form.current.submit();
        }
    }, [ data ]);

    if (data === false) {
        return  <></>;
    } else {
        return  <form ref={ form } method="post" action={ data.url }>
                {data.parameters.map( parameter =>
                    <input key={ parameter.name } type="hidden" name={ parameter.name } value={ parameter.value } />
                )}
                </form>
    }
}