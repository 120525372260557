import { getText } from '../../helpers/language';

export const BlockTitle = ({ title }) => {

    return  <>
            <div className="e__hd">
                <div className="e__hd__wrp">
                    <div className="e__hd__hdg">{ getText(title) }</div>
                </div>
            </div>
            </>;
}