import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { getURL, navigateTo } from '../../helpers/urls';
import config from '../../resources/config.json';
import { SVG_check, SVG_invoice, SVG_star } from '../elements/SVG';
import { BlockPricesPromo } from './BlockPricesPromo';

export const BlockPlan = ({ id }) => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const navigate = useNavigate();
    
    const handleOnChangePeriodicity = ({ target }) => {
        mainReducer.dispatch({
            type: 'changePeriodicity',
            payload: target.value
        });
    }

    const handleOpenBudget = () => {
        mainReducer.dispatch({
            type: 'changeIdLicense',
            payload: id
        });
        appReducer.dispatch({
            type: 'openModal',
            payload: {
                id: 'budget'
            }
        });
    }

    const handleSelectPlan = () => {
        mainReducer.dispatch({
            type: 'changeIdLicense',
            payload: id
        });
        navigateTo('premiumCheckout', navigate, environment, appReducer);
    }

    return  <>
                {((id != 0) || (mainReducer.state.user === false)) && 
                <article className={`e__plns__lst__plan e__plns__lst__plan--${id}${(config.plans[id].highlight) ? ' e__plns__lst__plan--highlight' : ''}`}>
                    <div className="e__plns__hdg__hd">
                        <span className="e__plns__hdg__hd__ico"><SVG_star /></span>
                        <div className="e__plns__hdg__hd__main">
                            <div className="e__plns__hdg__hd__shdg">{ getText('plan_educaplay') }</div>
                            <div className="e__plns__hdg__hd__wrp">
                                <span className="e__plns__hdg__hd__hdg">{ getText('plan_name_' + id)}</span>
                            </div>
                        </div>
                    </div>
                    { (id != 0) &&
                    <div className="e__plns__lst__plan__slct">
                        <div className="e__slct e__slct--min">
                            <label htmlFor={`plan_${id}_periodicity`}>{ getText('periodicidad' )}:</label>
                            <select id={`plan_${id}_periodicity`} onChange={ handleOnChangePeriodicity } value={ mainReducer.state.selectedLicense.periodicity }>
                                {Object.keys(config.periodicities).map( periodicity =>
                                    <option key={periodicity} value={periodicity}>
                                    { getText('periodicity_' + periodicity) }
                                    </option>
                                )}
                            </select>
                        </div>
                        { (mainReducer.state.selectedLicense.periodicity == config.defaultPeriodicity) &&
                            <span className="e__plns__dscount">
                                <span className="e__plns__dscount__nmbr">{ mainReducer.state.discountForPeriodicity[id][mainReducer.state.selectedLicense.periodicity]  + '%'}</span>
                                { getText('porcentaje_ahorro_por_periodicidad') }
                            </span>
                        }
                    </div>
                    }
                    <div className="e__plns__lst__plan__info">
                        {config.plans[id].advantages.map( ( element, index ) =>
                            <div key={index} className="e__plns__lst__plan__info__item">
                                <span className="e__plns__lst__plan__info__item__ico"><SVG_check /></span>
                                <span className="e__plns__lst__plan__info__item__txt">{ getText('advantage_' + id + '_' + element) }</span>
                            </div>
                        )}
                    </div>
                    { (id == 0) ?
                        <div className="e__plns__lst__plan__btns">
                            <a className="e__btn e__btn__scndry" href={ getURL(environment,'signup') + (environment.nc ? '?nc=true' : '') }>
                                <span className="e__btn__txt">{ getText('registrate_button') }</span>
                            </a>
                        </div>
                    :
                    <>
                    <BlockPricesPromo licenseId={ id } />
                    <div className="e__plns__lst__plan__btns">
                        {((mainReducer.state.currentLicense.periodicity == mainReducer.state.selectedLicense.periodicity) && (mainReducer.state.currentLicense.licenses == mainReducer.state.selectedLicense.licenses) && (mainReducer.state.currentLicense.id == id)) ?
                            <span className="e__txt">{ getText('plan_contratado') }</span>
                        :
                            <>
                            <button className="e__btn e__btn__qtrnry" onClick={ handleOpenBudget }>
                                <span className="e__btn__ico"><SVG_invoice /></span>
                                <span className="e__btn__txt">{ getText('presupuesto') }</span>
                            </button>
                            <button className="e__btn e__btn__prmry" onClick={ handleSelectPlan }>
                                <span className="e__btn__txt">{ getText((mainReducer.state.inProgressLicense.id == 0) ? 'elegir' : 'cambiar_suscripcion') }</span>
                            </button>
                            </>
                        }
                    </div>
                    </>
                    }
                </article>
                }
            </>;
}