import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { numberFormat } from '../../helpers/functions';
import { getText } from '../../helpers/language';

export const BlockPrices = ({ licenseId }) => {

    const { mainReducer, environment } = useContext( MainContext );
    
    return  <>
                <div className="e__plns__vlue">
                    {(mainReducer.state.ratesApplied[licenseId].totalNow != mainReducer.state.ratesApplied[licenseId].totalPeriodic) &&
                        <div className="e__plns__vlue__end e__plns__vlue__end--hlght">
                            <div className="e__plns__vlue__end__txt">
                                <span className="e__plns__vlue__ttl__txt__now">{ getText('a_pagar_ahora') } </span>
                                {((mainReducer.state.selectedLicense.vat != 0) && (mainReducer.state.ratesApplied[licenseId].totalNow != 0)) &&
                                    <span className="e__plns__vlue__ttl__txt__tax">({ mainReducer.state.selectedLicense.vat }% { getText('impuestos_incluidos') })</span>
                                }
                            </div>
                            <div className="e__plns__vlue__end__info">
                                <span className="e__plns__vlue__end__info__nmbr">{ numberFormat(mainReducer.state.ratesApplied[licenseId].totalNow,mainReducer.state.currency.decimals,environment) }</span>
                                <span className="e__plns__vlue__end__info__coin">{ mainReducer.state.currency.code }</span>
                            </div>
                        </div>
                    }
                    <div className={`e__plns__vlue__ttl${(mainReducer.state.ratesApplied[licenseId].totalNow == mainReducer.state.ratesApplied[licenseId].totalPeriodic) ? ' e__plns__vlue__ttl--hlght' : ''}`}>
                        <div className="e__plns__vlue__ttl__txt">
                            <span className="e__plns__vlue__ttl__txt__tot">{ getText('total_' + mainReducer.state.selectedLicense.periodicity) } </span>
                            {(mainReducer.state.selectedLicense.vat != 0) && (mainReducer.state.ratesApplied[licenseId].totalPeriodic != 0) &&
                                <span className="e__plns__vlue__ttl__txt__tax">({ mainReducer.state.selectedLicense.vat }% { getText('impuestos_incluidos') })</span>
                            }
                        </div>
                        <div className="e__plns__vlue__ttl__info">
                            <span className="e__plns__vlue__end__info__nmbr">{ numberFormat(mainReducer.state.ratesApplied[licenseId].totalPeriodic,mainReducer.state.currency.decimals,environment) }</span>
                            <span className="e__plns__vlue__end__info__coin">{ mainReducer.state.currency.code }</span>
                        </div>
                    </div>
                </div>                
            </>
}