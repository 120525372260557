export const scrollTo = (id) => {
    const element = document.getElementById(id);
    let offsetHead = 0;
    if (window.innerWidth >= 1024) {
        offsetHead = 100;
    } else {
        offsetHead = 32;
    }
    if (element !== null) {
        window.scrollTo(0,element.offsetParent.offsetTop + element.offsetTop - offsetHead);       
    }
}

export const scrollUp = () => {
    window.scrollTo(0,0);       
}

export const numberFormat = ( number, decimals, environment ) => {
    let lang = 'pt';
    if (environment.lang == 'en') {
        lang = 'en';
    }
    return parseFloat(number).toLocaleString(lang, {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}

export const deepSpread = (element) => {
    return JSON.parse(JSON.stringify(element));
}

export const equalObjects = ( object1, object2 ) => {
    if (JSON.stringify(object1) == JSON.stringify(object2)) {
        return true;
    } else {
        return false;
    }
}

export const getHash = async(data) => {
    const msgUint8 = new TextEncoder().encode(data);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const isEmail = ( email ) => {
    const patron = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return patron.test( email );
}

export const tsToDate = ( ts, lang, format='' ) => {
    const date = new Date(ts * 1000);
    const month = (date.getMonth() + 1).toString().padStart(2,'0');
    switch (format) {
    case 'paymentMethod':
        const yearPM = date.getFullYear().toString().substr(2,2);
        return month + '/' + yearPM;    
        break;
    default:
        const day = date.getDate().toString().padStart(2,'0');
        const year = date.getFullYear();
        if (lang == 'en') {
            return month + '/' + day + '/' + year;    
        } else {
            return day + '/' + month + '/' + year;    
        }
    }
}

export const tsToTime = ( ts ) => {
    let minutes = parseInt(ts / 60);
    const seconds = ts - (minutes * 60);
    const hours = parseInt(minutes / 60);
    minutes -= hours * 60;
    return hours.toString().padStart(2,'0') + ':' + minutes.toString().padStart(2,'0') + ':' + seconds.toString().padStart(2,'0');
}

export const getCookie = ( name ) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}

export const setCookie = ( name, value, environmentCookies ) => {
    document.cookie = name + '=' + value + '; max-age=31536000; path=/; domain=' + environmentCookies + '; secure; SameSite=None';
}

export const checkNIF = ( nif ) => {
    const DNI_REGEX = /^(\d{8})([A-Z])$/;
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    if ( nif.match( DNI_REGEX ) ) {
        const num = nif.match(/\d+/);
        return (nif[8] == 'TRWAGMYFPDXBNJZSQVHLCKE'[num%23]);
    } else if ( nif.match( CIF_REGEX ) ) {
        let match = nif.match( CIF_REGEX );
        let letter  = match[1],
            number  = match[2],
            control = match[3];
        let even_sum = 0;
        let odd_sum = 0;
        let n;
        for ( let i = 0; i < number.length; i++) {
            n = parseInt( number[i], 10 );
            if ( i % 2 === 0 ) {
                n *= 2;
                odd_sum += n < 10 ? n : n - 9;
            } else {
                even_sum += n;
            }
        }
        let control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
        if (control_digit == 10) {
            control_digit = 0;
        }
        let control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
        if ( letter.match( /[ABEH]/ ) ) {
            return (control == control_digit);
        } else if ( letter.match( /[KPQS]/ ) ) {
            return (control == control_letter);
        } else {
            return (control == control_digit || control == control_letter);
        }
    } else if ( nif.match( NIE_REGEX ) ) {
        let num = (nif[0]!='Z'? nif[0]!='Y'? 0: 1: 2) + nif.match(/\d+/);
        return (nif[8] == 'TRWAGMYFPDXBNJZSQVHLCKE'[num%23]);
    }
    return false;
}