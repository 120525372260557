import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { numberFormat } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import config from '../../resources/config.json';

export const BlockPricesPromo = ({ licenseId }) => {

    const { mainReducer, environment } = useContext( MainContext );
    
    return  <>
                <div className="e__plns__vlue">
                    <div className="e__plns__vlue__iva e__plns__vlue__iva--hlght">
                        <div className="e__plns__vlue__iva__txt">
                            <span className="e__plns__vlue__iva__txt__tot">
                                {(config.periodicities[mainReducer.state.selectedLicense.periodicity].months > 1) ?
                                    (mainReducer.state.selectedLicense.licenses > 1) ?
                                        getText('precio_licencia_mes')
                                    :
                                        getText('precio_equivalente_mensual')
                                :
                                    (mainReducer.state.selectedLicense.licenses > 1) ?
                                        getText('precio_licencia')
                                    :
                                        getText('precio_monthly')
                                }
                            </span>
                        </div>
                        <div className="e__plns__vlue__iva__info">
                            <span className="e__plns__vlue__end__info__nmbr">{ numberFormat(mainReducer.state.ratesApplied[licenseId].perUserAndMonth,mainReducer.state.currency.decimals,environment) }</span>
                            <span className="e__plns__vlue__end__info__coin">{ mainReducer.state.currency.code }</span>
                        </div>
                    </div>
                    {((config.periodicities[mainReducer.state.selectedLicense.periodicity].months > 1) || (mainReducer.state.selectedLicense.licenses > 1)) &&
                        <div className="e__plns__vlue__ttl">
                            <div className="e__plns__vlue__ttl__txt">
                                <span className="e__plns__vlue__ttl__txt__tot">{ getText('precio_' + mainReducer.state.selectedLicense.periodicity) } </span>
                            </div>
                            <div className="e__plns__vlue__ttl__info">
                                <span className="e__plns__vlue__end__info__nmbr">{ numberFormat(mainReducer.state.ratesApplied[licenseId].totalPeriodicWithoutVAT,mainReducer.state.currency.decimals,environment) }</span>
                                <span className="e__plns__vlue__end__info__coin">{ mainReducer.state.currency.code }</span>
                            </div>
                        </div>
                    }
                </div>                
            </>
}