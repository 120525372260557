import { useContext, useCallback } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { InputUpDown } from '../inputs/InputUpDown';
import { BlockPlan } from './BlockPlan';
import { numberFormat, scrollTo, tsToDate } from '../../helpers/functions';
import config from '../../resources/config.json';
import { BlockInfoLicense } from './BlockInfoLicense';
import { SVG_back } from '../elements/SVG';

export const BlockPlans = () => {   

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const handleChangeLicenses = useCallback( ( value ) => {
        const action = {
            type: 'changeLicenses',
            payload: value
        }
        mainReducer.dispatch(action);
    }, [ mainReducer ]);

    const handleOpenLicense = () => {
        appReducer.dispatch({
            type: 'openModal',
            payload: {
                id: 'license'
            }
        });
    }

    return  <>
            <section className="e__plns__lst__main">
                <div className="e__plns__lst__hd">
                    { (mainReducer.state.user !== false) &&
                        <>
                        <div className="e__plns__hdg__wrp">
                            <div className='e__plns__hdg__wrp__lst'>
                                {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense <= mainReducer.state.currentLicense.id)) &&
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_asignado') }:</div>
                                        <BlockInfoLicense license={ { id: mainReducer.state.subLicense } } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                }
                                {((mainReducer.state.inProgressLicense.id == mainReducer.state.currentLicense.id) && (mainReducer.state.inProgressLicense.periodicity == mainReducer.state.currentLicense.periodicity) && (mainReducer.state.inProgressLicense.licenses == mainReducer.state.currentLicense.licenses)) ?
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_contratado') }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.currentLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                :
                                    <>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_contratado') }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.inProgressLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('a_partir_de') } { tsToDate( mainReducer.state.inProgressLicense.expiration, environment.lang ) }:</div>
                                        <BlockInfoLicense license={ mainReducer.state.currentLicense } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                    </>
                                }
                                {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense > mainReducer.state.currentLicense.id)) &&
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{ getText('plan_asignado') }:</div>
                                        <BlockInfoLicense license={ { id: mainReducer.state.subLicense } } />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                        </>
                    }
                </div>
                <div className="e__count">                    
                    <span className="e__count__plns"><InputUpDown field="licenses" value={ mainReducer.state.selectedLicense.licenses } handleSave={ handleChangeLicenses } min={ 1 } max={ config.maxLicenses } /></span>
                    {(mainReducer.state.discountApplied != 0) &&
                        <span className="e__plns__dscount">
                            <span className="e__plns__dscount__nmbr">{ numberFormat(mainReducer.state.discountApplied,2,environment)  + '%' }</span>
                            { getText('porcentaje_ahorro_por_licencia') }
                        </span>
                    }
                </div>
                <div className={`e__plns__lst__wpr${(mainReducer.state.user === false) ? ' e__plns__lst__wpr--basic' : ''}`}>
                    {Object.keys(config.plans).map( index =>
                        <BlockPlan key={index} id={ index } />
                    )}
                </div>
                <div className="e__txt__lgl">{ getText('licencias_y_condiciones_disclaimer') } <button onClick={ handleOpenLicense } className="e__lnk">{ getText('licencias_y_condiciones') }</button></div>
                <div className="e__plns__lst__main__nv">
                    <button onClick={ () => scrollTo('sectionPriceTable') }>{ getText('comparar_title') }</button>
                    <button onClick={ () => scrollTo('sectionFAQ') } >{ getText('faq') }</button>
                </div>
            </section>
            </>;
}