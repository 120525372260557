import { getText } from '../../helpers/language';
import { SVG_back } from '../elements/SVG';

export const BlockBack = ({ title, onBack }) => {

    return  <>
            <div className="e__hd__nv">
                <button className="e__hd__nv__bck" onClick={ onBack }>
                    <span className="e__hd__nv__bck__ico">
                            <SVG_back />
                        </span>
                    <span className="e__hd__nv__bck__txt">{ getText(title) }</span>
                </button>
            </div>
            </>;
}