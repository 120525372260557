import { SVG_back } from '../elements/SVG';

export const BlockFAQElement = ({ id, title, text, stateExpanded, handleToggle }) => {

    return  <>
                <div className={`e__fq__item${(stateExpanded === id) ? ' act' : ''}`}>
                    <div className="e__fq__item__wpr">
                        <div className="e__fq__item__hd" onClick={ () => handleToggle( id ) }>
                            <span className="e__fq__item__hd__hdg">{ title }</span>
                            <span className="e__fq__item__hd__ico"><SVG_back /> </span>
                        </div>
                        { (stateExpanded === id) &&
                            <div className="e__fq__item__info">
                                { text }
                            </div>
                        }
                    </div>
                </div>
            </>;
}