import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { getInvoices } from '../../helpers/api';
import { getText } from '../../helpers/language';
import { BlockInvoicesInvoice } from './BlockInvoicesInvoice';

export const BlockInvoices = () => {

    const { environment, mainReducer } = useContext( MainContext );

    const [ stateExpanded, setStateExpanded ] = useState(false);

    useEffect( () => {
        if (mainReducer.state.invoices === false) {
            const data = {
                tokenID: environment.tokenID,
                environment: environment.entorno,
                budget: environment.budget
            }
            getInvoices(data).then(invoices => {
                const action = {
                    type: 'setInvoices',
                    payload: invoices
                };
                mainReducer.dispatch(action);
            });
        }
    }, [ mainReducer.state.invoices ]);

    if ((mainReducer.state.invoices === false) || (mainReducer.state.invoices.length == 0)) {
        return  <></>;
    } else {
        return  <>
                <fieldset className="e__plns__fact">
                    <legend>
                        { getText('facturas') }
                    </legend>
                    <div className="e__plns__fact__lst">
                        {(stateExpanded) ?
                            <>
                            <div className="e__plns__fact__lst__wpr">
                                {mainReducer.state.invoices.map( invoice =>
                                    <BlockInvoicesInvoice invoice={ invoice } key={`${invoice.series}${invoice.year}${invoice.number}`} />
                                )}
                            </div>
                            {(mainReducer.state.invoices.length > 1) &&
                                <button onClick={ () => setStateExpanded(false) } className="e__lnk">{ getText('ver_menos_facturas') }</button>
                            }
                            </>
                        :
                            <>
                            <div className="e__plns__fact__lst__wpr">
                                <BlockInvoicesInvoice invoice={ mainReducer.state.invoices[0] } />
                            </div>
                            {(mainReducer.state.invoices.length > 1) &&
                                <button onClick={ () => setStateExpanded(true) } className="e__lnk">{ getText('ver_todas_facturas') }</button>
                            }
                            </>
                        }
                    </div>
                </fieldset>
                </>;
    }
}