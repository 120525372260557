import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { getURL } from '../../helpers/urls';
import { SVG_download } from '../elements/SVG';

export const BlockInvoicesInvoice = ({ invoice }) => {

    const { environment } = useContext( MainContext );

    return  <>
                <div className="e__plns__fact__lst__wpr__item">
                    <span className="e__plns__fact__lst__wpr__item__code">{invoice.series}{invoice.year}{invoice.number.toString().padStart(4,'0')}</span>
                    <span className="e__plns__fact__lst__wpr__item__date">{tsToDate(invoice.date,environment.lang)}</span>
                    <a className="e__btn e__btn__trtry e__btn__trtry--mini" href={ getURL(environment,'facturas') + invoice.id + '/?sign=' + invoice.sign} target="_blank" title={ getText('factura') + invoice.series + invoice.year + invoice.number.toString().padStart(4,'0') } key={invoice.id}>
                        <span className="e__btn__ico">
                            <SVG_download />
                        </span>
                    </a>
                </div>
            </>;
}