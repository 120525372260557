import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { MainContext } from '../../contexts/main';

export const BlockLocation = () => {

    const { appReducer } = useContext( MainContext );

    const location = useLocation();

    const firstLocation = useRef(true);

    useEffect(() => {
        if (firstLocation.current) {
            firstLocation.current = false;
        } else {
            appReducer.dispatch({
                type: 'clearNotifications'
            });
            appReducer.dispatch({
                type: 'clearFieldErrors'
            });
        }
    }, [ location ]);

    return  <>
            </>;
}