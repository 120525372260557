import config from '../resources/config.json';

export const getEnvironment = () => {
    const datosGenerales = document.getElementById('datosGenerales');
    let environmentDatosGenerales = {
        nonPersonalizedAds: '0',
        files: '',
        resources: '',
        entorno: '',
        idUsuario: '',
        tokenID: '',
        super: '',
        anonymousToken: '',
        nc: false
    };
    const keys = Object.keys(environmentDatosGenerales);
    for (let i=0;i<keys.length;i++) {
        if (datosGenerales) {
            let dato = datosGenerales.getAttribute('data-' + keys[i]);
            if (dato) {
                environmentDatosGenerales[keys[i]] = dato;
            }
        }
    }
    let entornoCookies = datosGenerales.getAttribute('data-entornoCookies');
    if (entornoCookies === null) {
        entornoCookies = environmentDatosGenerales.entorno;
    }
    const environment = {
        ...environmentDatosGenerales,
        entornoCookies: entornoCookies,
        lang: '',
        subdomain: '',
        license: {
            id: 0,
            licenses: 1,
            periodicity: config.defaultPeriodicity
        },
        budget: '',
        email: '',
        action: '',
        checkAuthPaymentMethod: ''
    };
    environment['lang'] = document.documentElement.getAttribute('lang');
    if (environment['lang'] == 'en') {
        environment['subdomain'] = 'www';
    } else {
        environment['subdomain'] = environment['lang'];
    }
    if (environment.resources.substring(0,8) != 'https://') {
        environment['resources'] = 'https://' + environment.subdomain + '.' + environment.entorno + environment.resources;
    }
    const params = new URLSearchParams(window.location.search)
    const licenseId = params.get('id');
    const licenseNum = params.get('licenses');
    const licensePeriodicity = params.get('periodicity');
    const budget = params.get('budget');
    const checkAuthPaymentMethod = params.get('check');
    const email = params.get('email');
    const nc = params.get('nc');
    const action = document.getElementById('facturacionReact').getAttribute('data-action');
    if (licenseId !== null) {
        Object.keys(config.plans).forEach( mLicenseId => {
            if (mLicenseId == licenseId) {
                environment['license']['id'] = parseInt(licenseId);
            }
        });
    }
    if (licenseNum !== null) {
        if ((licenseNum > 0)) {
            if (licenseNum <= config.maxLicenses) {
                environment['license']['licenses'] = parseInt(licenseNum);
            } else {
                environment['license']['licenses'] = config.maxLicenses;
            }
        } 
    }
    if (licensePeriodicity !== null) {
        Object.keys(config.periodicities).forEach( mPeriodicity => {
            if (mPeriodicity == licensePeriodicity) {
                environment['license']['periodicity'] = licensePeriodicity;
            }
        });
    }
    if (budget !== null) {
        environment['budget'] = budget;
    }
    if (nc == 'true') {
        environment['nc'] = true;
    }
    if (checkAuthPaymentMethod !== null) {
        environment['checkAuthPaymentMethod'] = checkAuthPaymentMethod;
    }
    if (email !== null) {
        environment['email'] = email;
    }
    if (action !== null) {
        environment['action'] = action;
    }
    return environment;
}