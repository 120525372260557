import languageResources from '../resources/language.json';

export const getText = (resource) => {
    let texto = '';
    const lang = document.documentElement.getAttribute('lang');
    if (resource != undefined) {
        try {
            texto = languageResources[resource][lang];
        } catch (error) {
            texto = '#' + resource + '#';
        }
    }
    return texto;
}