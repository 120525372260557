import './scss/styles.scss';
import { MainContext, getContext } from './contexts/main';
import { ScreenControllerMain } from './components/screens/ScreenControllerMain';

export const App = () => {
    
    return  <MainContext.Provider value={ getContext() }>
                <ScreenControllerMain />
            </MainContext.Provider>; 
    
}