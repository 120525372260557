import { useContext, useState } from 'react';
import { BlockFAQElement } from './BlockFAQElement';
import { getText } from '../../helpers/language';
import { getURL } from '../../helpers/urls';
import { MainContext } from '../../contexts/main';

export const BlockFAQ = () => {

    const { environment } = useContext( MainContext );

    const [ stateExpanded, setStateExpanded ] = useState(false);

    const handleToggle = ( id ) => {
        if (id === stateExpanded) {
            setStateExpanded( false );
        } else {
            setStateExpanded( id );
        }
    }

    return  <>
            <section id="sectionFAQ" className="e__fq">
                <div className="e__fq__wpr">
                    <div className="e__fq__hd">FAQ</div>
                    <BlockFAQElement id={ 1 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_1_title') } text={
                        <>
                            <p>{ getText('faq_1_text_1')} </p>
                            <p>{ getText('faq_1_text_2')} </p>
                            <p>{ getText('faq_1_text_3')} </p>
                            <p>{ getText('faq_1_text_4')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 2 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_2_title') } text={
                        <>
                            <p>{ getText('faq_2_text_1')} </p>
                            <p>{ getText('faq_2_text_2')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 3 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_3_title') } text={
                        <>
                            <p>{ getText('faq_3_text_1')} </p>
                            <p>{ getText('faq_3_text_2')} </p>
                            <p>{ getText('faq_3_text_3')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 4 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_4_title') } text={
                        <>
                            <p>{ getText('faq_4_text_1')} </p>
                            <p>{ getText('faq_4_text_2')} </p>
                            <p>{ getText('faq_4_text_3')} </p>
                            <p>{ getText('faq_4_text_4')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 5 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_5_title') } text={
                        <>
                            <p>{ getText('faq_5_text_1')} </p>
                            <p>{ getText('faq_5_text_2')} </p>
                            <p>{ getText('faq_5_text_3')} </p>
                            <p>{ getText('faq_5_text_4')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 6 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_6_title') } text={
                        <>
                            <p>{ getText('faq_6_text_1')} </p>
                            <p>{ getText('faq_6_text_2')} <a href={ getURL(environment,'companyPlan') + (environment.nc ? '?nc=true' : '') } title={ getText('planazo')} target="_blank">{ getText('planazo')}</a> { getText('faq_6_text_4')}</p>
                            <p>{ getText('faq_6_text_5')} </p>
                            <p>{ getText('faq_6_text_6')} </p>
                            <p>{ getText('faq_6_text_7')} </p>
                            <p>{ getText('faq_6_text_8')} </p>
                            <p>{ getText('faq_6_text_9')} </p>
                            <p>{ getText('faq_6_text_10')} </p>
                            <p>{ getText('faq_6_text_11')} </p>
                            <p>{ getText('faq_6_text_12')} </p>
                            <p>{ getText('faq_6_text_13')} </p>
                            <p>{ getText('faq_6_text_14')} </p>
                        </>
                    } />
                    <BlockFAQElement id={ 7 } stateExpanded={ stateExpanded } handleToggle={ handleToggle } title={ getText('faq_7_title') } text={
                        <>
                            <p>{ getText('faq_7_text_1')} </p>
                            <p>{ getText('faq_7_text_2')} </p>
                            <p>{ getText('faq_7_text_3')} </p>
                            <p>{ getText('faq_7_text_4')} </p>
                            <p>{ getText('faq_7_text_5')} </p>
                        </>
                    } />
                </div>
            </section>
            </>;
}