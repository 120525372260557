import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { getURL } from '../../helpers/urls';
import { BlockLoading } from './BlockLoading';
import { SVG_downloadInvoice } from '../elements/SVG';

export const BlockDownloadBudget = ({ overrideCode = false, inContext = false }) => {

    const { environment, mainReducer } = useContext( MainContext );

    return  <>
            <fieldset className="e__plns__pdf">
                <legend>
                    { getText((inContext) ? 'descargar' : 'descargar_presupuesto') }
                    <span className="e__btn__info">
                        <button className="e__btn" aria-label={ getText('mas_informacion') }title={ getText('mas_informacion') }>i</button>
                        <span className="e__btn__info__wp">
                            { getText('descargar_presupuesto_info') }
                        </span>
                    </span>
                </legend>
                { ((overrideCode === false) && (mainReducer.state.budgetGenerated === false)) ?
                    <BlockLoading />
                :
                    <div className="e__plns__pdf__wpr">
                        <span className="e__txt__info">
                            { getText('descargar_presupuesto_txt') }
                        </span>
                        <a href={ getURL(environment,'viewBudget') + ( (overrideCode) ? overrideCode: mainReducer.state.budgetGenerated ) + '/' } target="_blank" title={ getText((inContext) ? 'descargar' : 'descargar_presupuesto') } className="e__btn e__btn__qtrnry">
                            <span className="e__btn__ico"><SVG_downloadInvoice /></span>
                            <span className="e__btn__txt">{ getText((inContext) ? 'descargar' : 'descargar_presupuesto') }</span>
                        </a>
                    </div>
                }
            </fieldset>
            </>;
}