import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { ScreenData } from './ScreenData';
import { navigateTo, redirectToLogin, redirectToLoginBudget } from '../../helpers/urls';
import { ScreenIdentify } from './ScreenIdentify';
import { analyticsPageView } from '../../helpers/analytics';
import { scrollUp } from '../../helpers/functions';

export const ScreenControllerCheckout = ({ mode }) => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const [ stateScreen, setStateScreen ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        analyticsPageView(environment);
    }, []);

    useEffect(() => {
        scrollUp();
    }, [ stateScreen ]);

    useEffect(() => {
        let action = mode;
        if (mainReducer.state.budget === false) {
            if (mainReducer.state.selectedLicense.id > 0) {
                if (mainReducer.state.selectedLicense.payer_email == '') {
                    if (mode == 'budget') {
                        action = 'loginBudget';
                    } else {
                        action = 'login';
                    }
                }
            } else {
                action = 'premium';
            }
        } else {
            if (mainReducer.state.budget.accomplished) {
                if (mainReducer.state.selectedLicense.payer_email == '') {
                    action = 'identify';
                } else {
                    if (mainReducer.state.currentLicense.payer_email != mainReducer.state.selectedLicense.payer_email)  {
                        action = 'error';
                    } else {
                        if (mainReducer.state.selectedLicense.id > 0) {
                            action = 'edit';
                        } else {
                            action = 'premium';
                        }
                    }
                }
            } else {
                if (mainReducer.state.selectedLicense.payer_email == '') {
                    action = 'identify';
                }
            }
        }
        switch (action) {
        case 'login':
            redirectToLogin( environment, mainReducer.state.selectedLicense );
            break;
        case 'loginBudget':
            redirectToLoginBudget( environment, mainReducer.state.selectedLicense );
            break;
        case 'premium':
            navigateTo('premium', navigate, environment, appReducer, true);
            break;
        case 'error':
            const actionErr = {
                type: 'setError',
                payload: 'permiso'
            };
            appReducer.dispatch(actionErr);
            break;
        case 'edit':        
        case 'identify':        
        case 'standard':
        case 'budget':
            setStateScreen(action);
            break;
        }
    }, [ mainReducer.state.selectedLicense.payer_email, mainReducer.state.selectedLicense.id, mode, mainReducer.state.currentLicense.payer_email ]);

    switch (stateScreen) {
    case 'edit':
    case 'budget':
    case 'standard':
        return <ScreenData mode={ stateScreen } currentLocation="checkout" />;
        break;
    case 'identify':
        return <ScreenIdentify />;
        break;
    default:
        return <></>;
    }
    
}