import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToDate } from '../../helpers/functions';
import { getText } from '../../helpers/language';
import { SVG_okcheck, SVG_paymentMethod, SVG_xcircle } from '../elements/SVG';

export const BlockSummaryPaymentSaved = ({ changeScreen }) => {

    const { mainReducer, environment } = useContext( MainContext );

    const [ stateShowCurrentPaymentMethod, setStateShowCurrentPaymentMethod ] = useState(false);

    useEffect( () => {
        if ((mainReducer.state.realUser === false) || (mainReducer.state.currentPaymentMethod === false) || ((mainReducer.state.realUser !== false) && (mainReducer.state.realUser.email != mainReducer.state.currentPaymentMethod.payer))) {
            setStateShowCurrentPaymentMethod(false);
        } else {
            let currentPaymentMethodInList = false;
            for (const index in mainReducer.state.paymentMethods) {
                if (mainReducer.state.paymentMethods[index].id == mainReducer.state.currentPaymentMethod.id) {
                    currentPaymentMethodInList = true;
                    break;
                }
            }
            setStateShowCurrentPaymentMethod(!currentPaymentMethodInList);
        }
    }, [ mainReducer.state.paymentMethods, mainReducer.state.currentPaymentMethod ]);

    const handleOnChange = ( paymentMethod ) => {
        const action = {
            type: 'changePaymentMethod',
            payload: paymentMethod
        };
        mainReducer.dispatch(action);
    }

    return  <>           
                <div className="e__plns__opt__pay__wpr">
                    {((mainReducer.state.realUser !== false) && (mainReducer.state.currentPaymentMethod !== false) && (mainReducer.state.realUser.email != mainReducer.state.currentPaymentMethod.payer)) &&
                        <div className="e__plns__opt__pay__wpr__save">
                            <input type="radio" id={`paymentMethod${mainReducer.state.currentPaymentMethod.id}`} value={mainReducer.state.currentPaymentMethod.id} onChange={() => handleOnChange(mainReducer.state.currentPaymentMethod.id)} checked={(mainReducer.state.selectedPaymentMethod !== false) && (mainReducer.state.selectedPaymentMethod == mainReducer.state.currentPaymentMethod.id)} />
                            <label htmlFor={`paymentMethod${mainReducer.state.currentPaymentMethod.id}`}>
                                <span className="e__plns__opt__pay__wpr__save__ico">
                                    <SVG_paymentMethod type="payer" />
                                </span>                                   
                                <div className="e__plns__opt__pay__wpr__save__info">
                                    <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                        <div className="e__plns__opt__pay__wpr__save__info__hd">{ getText('payment_method_title_payer') }</div>
                                        <div className="e__plns__opt__pay__wpr__save__info__db">{ mainReducer.state.currentPaymentMethod.payer }</div>
                                    </div>
                                </div>
                                <span className="e__ico">
                                {(mainReducer.state.currentPaymentMethod.id == mainReducer.state.selectedPaymentMethod) &&
                                    <>
                                    {(mainReducer.state.currentPaymentMethod.status == 'success') ?
                                        <SVG_okcheck />
                                    :
                                        <SVG_xcircle />
                                    }
                                    </>
                                }
                                </span>
                            </label>
                        </div>
                    }
                    {mainReducer.state.paymentMethods.map( paymentMethod =>
                        <div className="e__plns__opt__pay__wpr__save" key={paymentMethod.id}>
                            <input type="radio" id={`paymentMethod${paymentMethod.id}`} value={paymentMethod.id} onChange={() => handleOnChange(paymentMethod.id)} checked={(mainReducer.state.selectedPaymentMethod !== false) && (mainReducer.state.selectedPaymentMethod == paymentMethod.id)} />
                            <label htmlFor={`paymentMethod${paymentMethod.id}`}>
                                <span className="e__plns__opt__pay__wpr__save__ico">
                                    <SVG_paymentMethod type={paymentMethod.type} />
                                </span>                                   
                                <div className="e__plns__opt__pay__wpr__save__info">
                                    <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                        <div className="e__plns__opt__pay__wpr__save__info__hd">{ getText('payment_method_title_' + paymentMethod.type) }</div>
                                        <div className="e__plns__opt__pay__wpr__save__info__db">{ getText('payment_method_prefix_' + paymentMethod.type) } { paymentMethod.description }</div>
                                    </div>
                                    {(paymentMethod.expires !== false) &&
                                        <div className="e__plns__opt__pay__wpr__save__info__date">{ getText('caduca')}: { tsToDate( paymentMethod.expires, environment.lang , 'paymentMethod') }</div>
                                    }
                                </div>
                                {(paymentMethod.id == mainReducer.state.selectedPaymentMethod) &&
                                    <span className="e__ico">
                                        <SVG_okcheck />
                                    </span>
                                }
                            </label>
                        </div>
                    )}
                    {(stateShowCurrentPaymentMethod) &&
                    <div className="e__plns__opt__pay__wpr__save e__plns__opt__pay__wpr__save--rrr">
                        <div className="e__plns__opt__pay__wpr__save__item">
                            <span className="e__plns__opt__pay__wpr__save__ico">
                                <SVG_paymentMethod type={mainReducer.state.currentPaymentMethod.type} />
                            </span>                                   
                            <div className="e__plns__opt__pay__wpr__save__info">
                                <div className="e__plns__opt__pay__wpr__save__info__wpr">
                                    <div className="e__plns__opt__pay__wpr__save__info__hd">{ getText('payment_method_title_' + mainReducer.state.currentPaymentMethod.type) }</div>
                                    <div className="e__plns__opt__pay__wpr__save__info__db">{ getText('payment_method_prefix_' + mainReducer.state.currentPaymentMethod.type) } { mainReducer.state.currentPaymentMethod.description }</div>
                                </div>
                                {(mainReducer.state.currentPaymentMethod.expires !== false) &&
                                    <div className="e__plns__opt__pay__wpr__save__info__date">{ getText('caduca')}: { tsToDate( mainReducer.state.currentPaymentMethod.expires, environment.lang , 'paymentMethod') }</div>
                                }
                            </div>
                            {(mainReducer.state.currentPaymentMethod.id == mainReducer.state.selectedPaymentMethod) &&
                                <span className="e__ico">
                                {(mainReducer.state.currentPaymentMethod.status == 'success') ?
                                    <SVG_okcheck />
                                :
                                    <SVG_xcircle />
                                }
                                </span>
                            }
                        </div>
                    </div>
                    }
                    <button className="e__btn e__btn__trtry" onClick={ () => changeScreen('new') }>
                        <span className="e__btn__txt">{ getText('nuevo_metodo_de_pago') }</span>
                    </button>
                </div>
            </>;
}