import { useState, useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { analyticsPageView } from '../../helpers/analytics';
import { scrollUp } from '../../helpers/functions';
import { ScreenData } from './ScreenData';
import { ScreenInfo } from './ScreenInfo';

export const ScreenControllerUser = () => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const [ stateScreen, setStateScreen ] = useState(false);

    useEffect(() => {
        analyticsPageView(environment);
    }, []);

    useEffect(() => {
        scrollUp();
    }, [ stateScreen ]);

    useEffect(() => {
        let action = 'edit';
        if (mainReducer.state.budget === false) {
            if (mainReducer.state.currentLicense.id == 0) {
                action = 'info';
            }
        } else {
            if (mainReducer.state.budget.accomplished) {
                if ((mainReducer.state.selectedLicense.payer_email != '') && (mainReducer.state.currentLicense.payer_email != mainReducer.state.selectedLicense.payer_email))  {
                    action = 'error';
                } else {
                    if (mainReducer.state.currentLicense.id == 0) {
                        action = 'info';
                    }
                }
            } else {
                action = 'standard';
            }
        }

        switch (action) {
        case 'error':
            const actionErr = {
                type: 'setError',
                payload: 'permiso'
            };
            appReducer.dispatch(actionErr);
            break;
        case 'edit':        
        case 'info':        
        case 'standard':
            setStateScreen(action);
            break;
        }
    }, [ mainReducer.state.selectedLicense.payer_email, mainReducer.state.currentLicense.id, mainReducer.state.currentLicense.payer_email ]);

    switch (stateScreen) {
    case 'info':
        return <ScreenInfo />;
        break;
    case 'edit':
    case 'standard':
        return <ScreenData mode={ stateScreen } currentLocation="user" />;
        break;
    default:
        return <></>;
    }
    
}