import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../contexts/main';
import { analyticsPageView } from '../../helpers/analytics';
import { scrollUp } from '../../helpers/functions';
import { navigateTo } from '../../helpers/urls';
import { ScreenPlans } from './ScreenPlans';

export const ScreenControllerPlans = () => {

    const { environment, mainReducer, appReducer } = useContext( MainContext );

    const [ stateScreen, setStateScreen ] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        analyticsPageView(environment);
    }, []);

    useEffect(() => {
        scrollUp();
    }, [ stateScreen ]);

    useEffect(() => {
        let action = 'plans';
        if (mainReducer.state.budget !== false) {
            if (mainReducer.state.budget.accomplished) {
                if ((mainReducer.state.selectedLicense.payer_email != '') && (mainReducer.state.currentLicense.payer_email != mainReducer.state.selectedLicense.payer_email))  {
                    action = 'error';
                } else {
                    if ((mainReducer.state.selectedLicense.id > 0) || (mainReducer.state.selectedLicense.payer_email == '')) {
                        action = 'checkout'
                    }
                }
            } else {
                action = 'checkout'
            }
        }
        switch (action) {
        case 'checkout':
            navigateTo('premiumCheckout', navigate, environment, appReducer, true);
            break;
        case 'error':
            const actionErr = {
                type: 'setError',
                payload: 'permiso'
            };
            appReducer.dispatch(actionErr);
            break;
        case 'plans':
            setStateScreen(action);
            break;
        }
    }, [ mainReducer.state.selectedLicense.payer_email, mainReducer.state.selectedLicense.id, mainReducer.state.currentLicense.payer_email ]);

    switch (stateScreen) {
    case 'plans':
        return <ScreenPlans />;
        break;
    default:
        return <></>;
    }
    
    
}