import { v4 as uuid } from 'uuid';

export const appReducer = ( state, action ) => {
    switch (action.type) {
    case 'openModal':
        const stateOpenModal = {...state};
        if (action.payload.value === undefined) {
            stateOpenModal.modals[action.payload.id] = true;
        } else {
            stateOpenModal.modals[action.payload.id] = action.payload.value;
        }
        return stateOpenModal;
        break;
    case 'closeModal':
        const stateCloseModal = {...state};
        stateCloseModal.modals[action.payload] = false;
        return stateCloseModal;
        break;
    case 'setError':
        return {
            ...state,
            error: action.payload
        };
        break;
    case 'clearNotifications':
        return {
            ...state,
            notifications: []
        };
        break;
    case 'setNotifications':
        for (const index in action.payload) {
            if (action.payload[index].id === undefined) {
                action.payload[index] = {
                    ...action.payload[index],
                    id: uuid()
                }
            }
        }
        return {
            ...state,
            notifications: action.payload
        };
        break;
    case 'deleteNotification':
        const notifications = [...state.notifications];
        let positionNotification = 0;
        let deleteNotification = false;
        for (const index in notifications) {
            if (notifications[index].id == action.payload) {
                deleteNotification = positionNotification;
                break;
            }
            positionNotification++;
        }
        if (deleteNotification !== false) {
            notifications.splice(deleteNotification,1);
        }
        return {
            ...state,
            notifications: notifications
        };
        break;
    case 'clearFieldErrors':
        return {
            ...state,
            fieldErrors: []
        };
        break;
    case 'setFieldErrors':
        return {
            ...state,
            fieldErrors: action.payload
        };
        break;
    case 'deleteFieldError':
        const fieldErrors = [...state.fieldErrors];
        let positionField = 0;
        let deleteField = false;
        fieldErrors.forEach( ( error ) => {
            if ((error.section == action.payload.section) && (error.field == action.payload.field) && (error.error == action.payload.error)) {
                deleteField = positionField;
            }
            positionField++;
        });
        if (deleteField !== false) {
            fieldErrors.splice(deleteField,1);
        }
        return {
            ...state,
            fieldErrors: fieldErrors
        };
        break;
    default:
        return state;
    }
}

export const getAppInitialState = () => {
    return {
        error: false,
        modals: {
            budget: false,
            license: false,
            video: false
        },
        notifications: [],
        fieldErrors: []
    };
}