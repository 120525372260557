import { useContext, useEffect, useRef, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { tsToTime } from '../../helpers/functions';
import { getText } from '../../helpers/language';

export const NoticeDataPending = () => {

    const expirationPeriod = 3600;
    const expiration = parseInt(Date.now() / 1000) + expirationPeriod;

    const [ stateTime, setStateTime ] = useState( tsToTime(expirationPeriod) );

    const { mainReducer, appReducer } = useContext( MainContext );

    const mounted = useRef(false);

    const handleTick = () => {
        if (mounted.current) {
            const time = expiration - parseInt(Date.now() / 1000);
            if (time >= 0) {
                setTimeout(handleTick,1000);
                setStateTime( tsToTime(time) );
            } else {
                appReducer.dispatch({
                    type: 'setNotifications',
                    payload: [
                        {
                            type: 'error',
                            content: <>
                                <span className="e__notice__hd">{ getText('notice_data_pending_title')}</span>
                                <span className="e__notice__txt">
                                <strong>{ getText('notice_data_pending_text1') } { mainReducer.state.currentLicense.payer_email } { getText('notice_data_pending_text2') }</strong><br />
                                    { getText('notice_data_pending_text7') }<br />
                                    { getText('notice_data_pending_text6') } <a href={`mailto:${ getText('soporte@educaplay.com') }`} title={ getText('soporte@educaplay.com') }>{ getText('soporte@educaplay.com') }</a>.
                                </span>
                            </>
                        }
                    ]
                });
            }
        }
    }

    useEffect(() => {
        mounted.current = true;
        setTimeout(handleTick,1000);
        return () => {
            mounted.current = false;
        };
    }, []);
    
    return  <>
                <span className="e__notice__hd">{ getText('notice_data_pending_title')}</span>
                <span className="e__notice__txt">
                    <strong>{ getText('notice_data_pending_text1') } { mainReducer.state.currentLicense.payer_email } { getText('notice_data_pending_text2') }</strong><br />
                    { getText('notice_data_pending_text3') } <span className="e__notice__txt__hlght">{ stateTime }</span> { getText('notice_data_pending_text4') }<br />
                    { getText('notice_data_pending_text5') }<br />
                    { getText('notice_data_pending_text6') } <a href={`mailto:${ getText('soporte@educaplay.com') }`} title={ getText('soporte@educaplay.com') }>{ getText('soporte@educaplay.com') }</a>.
                </span>
            </>
    
}