import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { getURL } from '../../helpers/urls';

export const BlockBannerCompany = () => {

    const { environment } = useContext( MainContext );

    return  <>
            <a className="e__plns__lst__bnn" href={ getURL(environment,'companyPlan') + (environment.nc ? '?nc=true' : '') } title={ getText('planazo') } target="_blank">
                <img className="e__plns__lst__bnn__dsk" src={`${environment.resources}img/companyPlan_${environment.lang}.png`} alt={ getText('planazo') } />
                <img className="e__plns__lst__bnn__mob" src={`${environment.resources}img/companyPlan_mob_${environment.lang}.png`} alt={ getText('planazo') } />
            </a>
            </>;
}