import ReactDOM from 'react-dom/client';
import { App } from './App';

import * as Sentry from "@sentry/react";

// Configurar sourcemaps: npx @sentry/wizard@latest -i sourcemaps

Sentry.init({
  dsn: "https://a44f8b9f5cd1e61e3649e0572ed78290@o4505165569261568.ingest.sentry.io/4505862272581632",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/www\.educaplay\.com/, /^https:\/\/es\.educaplay\.com/, /^https:\/\/fr\.educaplay\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('facturacionReact'));
root.render(<App />);