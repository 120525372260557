import { forwardRef, useContext, useEffect, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { SVG_xcircle } from '../elements/SVG';

export const InputSelect = forwardRef(({ field, section, value, list, canBeEmpty = false, handleSave }, ref) => {
    const { appReducer } = useContext( MainContext );

    const [ stateError, setStateError ] = useState(false);

    useEffect( () => {
        let displayError = false;
        appReducer.state.fieldErrors.forEach( ( error ) => {
            if ((error.section == section) && (error.field == field)) {
                displayError = error.error;
            }
        });
        if (displayError === false) {
            setStateError(false);
        } else {
            setStateError(displayError);
        }
    }, [ appReducer.state.fieldErrors ]);

    const handleChange = ({ target }) => {
        handleSave( target.value, field, section );       
        if (stateError !== false) {
            appReducer.dispatch({
                type: 'deleteFieldError',
                payload: {
                    section: section,
                    field: field,
                    error: stateError
                }
            });
        }
    }

    return  <>
                <div className={`e__form__item${(stateError === false) ? '' : ' rrr'}`}>
                    <div className="e__slct">
                        <label htmlFor={`field_${section}_${field}`}>{ getText('field_' + section + '_' + field + '_name') }</label>
                        <select ref={ ref } id={`field_${section}_${field}`} value={ value } onChange={ handleChange }>
                            { (canBeEmpty) && 
                                <option value=""></option>
                            }
                            { (list !== false) &&
                                Object.keys(list).map( key =>
                                    <option key={ key } value={ key }>{ list[key] }</option>
                                )
                            }
                        </select>
                        {((stateError !== false) && (stateError != 'unknown')) &&
                            <span className="e__txt__error">{ getText('error_field_' + stateError) }</span>
                        }
                        {stateError !== false &&
                            <span className="e__form__item__ico">
                                <SVG_xcircle />
                            </span>
                        }
                    </div>
                </div>
            </>;

});