/* import ReactGA from 'react-ga'; */

let initialized = false;

const analyticsInitialize = () => {
    /*
    if (!initialized) {
        ReactGA.initialize('UA-2165293-2', {
            debug: false
        });
        initialized = true;
    }
    */
}

export const analyticsPageView = (environment) => {
    /*
    if (environment.entorno == 'educaplay.com') {
        analyticsInitialize();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    */
}

export const analyticsEvent = (environment, category, action, label = null, value = null) => {
    /*
    if (environment.entorno == 'educaplay.com') {
        analyticsInitialize();
        let event = {
            category: category,
            action: action
        };
        if (label !== null) {
            event.label = label;
        }
        if (value !== null) {
            event.value = parseInt(value);
        }
        ReactGA.event(event);
    }
    */
}