import { getText } from '../../helpers/language';
import { SVG_loading } from '../elements/SVG';

export const ScreenLoading = ({ text = false }) => {
    
    return  <div className="crea__loading">
                <span className="crea__loading__simbolo">
                    <SVG_loading />         
                </span>
                <div className="crea__loading__txt">
                    <div className="crea__loading__txt__cargando">{ getText((text === false) ? 'cargando' : text) }</div>
                </div>
            </div>;

}