import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ScreenControllerPlans } from '../components/screens/ScreenControllerPlans';
import { ScreenControllerCheckout } from '../components/screens/ScreenControllerCheckout';
import { ScreenControllerUser } from '../components/screens/ScreenControllerUser';
import { MainContext } from '../contexts/main';
import { getText } from '../helpers/language';
import { getURL } from '../helpers/urls';
import { BlockLocation } from '../components/blocks/BlockLocation';

export const MainRouter = () => {

    const { mainReducer, environment, appReducer } = useContext( MainContext );

    useEffect( () => {
        if (environment.action != '') {
            const notifications = [];
            switch (environment.action) {
            case 'payment_complete':
                notifications.push({
                    type: 'ok',
                    content: <>
                                {getText('notification_' + environment.action)}
                                { (mainReducer.state.currentLicense.licenses > 1) &&
                                    (((mainReducer.state.realUser !== false) && (mainReducer.state.realUser.id == mainReducer.state.user.id)) ?
                                        <>
                                            {'. ' + getText('notification_' + environment.action + '_licenses') + ' '}
                                            <a href={`${ getURL( environment, 'perfilUsuario' ) }${ mainReducer.state.realUser.slug }licenses/`} title={ getText('gestionar_licencias') }>{ getText('gestionar_licencias') }</a>
                                        </>
                                    :
                                        <>
                                            {'. ' + getText('notification_' + environment.action + '_licenses_noadmin')}
                                        </>)
                                }
                            </>
                });
                break;
            default:
                notifications.push({
                    type: 'ok',
                    content: getText('notification_' + environment.action)
                });
            }
            appReducer.dispatch({
                type: 'setNotifications',
                payload: notifications
            });
        }
    }, []);

    return (
        <BrowserRouter>
            <BlockLocation />
            <Routes>
                <Route path="premium/checkout" element={ <ScreenControllerCheckout mode="standard" /> } />
                <Route path="premium/budget" element={ <ScreenControllerCheckout mode="budget" /> } />
                {(mainReducer.state.realUser !== false) && 
                    <>
                    <Route path={`usuario/${ mainReducer.state.realUser.slug }plan/`} element={ <ScreenControllerUser /> } />
                    <Route path={`user/${ mainReducer.state.realUser.slug }plan/`} element={ <ScreenControllerUser /> } />
                    <Route path={`utilisateur/${ mainReducer.state.realUser.slug }plan/`} element={ <ScreenControllerUser /> } />
                    </>
                }
                <Route path="*" element={ <ScreenControllerPlans /> } />
            </Routes>
        </BrowserRouter>
    )
}