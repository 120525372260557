import { Fragment, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { getPaymentMethodStructure, getPaymentMethodsTypes } from '../../helpers/paymentMethods';
import { SVG_paymentMethod } from '../elements/SVG';
import { BlockSummaryPaymentExtraData } from './BlockSummaryPaymentExtraData';

export const BlockSummaryPaymentNew = ({ changeScreen }) => {

    const { mainReducer } = useContext( MainContext );

    useEffect( () => {
        if (mainReducer.state.newPaymentMethod === false) {
            const action = {
                type: 'changeNewPaymentMethod',
                payload: {
                    type: false,
                    data: false
                }
            };
            mainReducer.dispatch(action);
        }       
    }, []);

    const handleOnChangePaymentType = ( type ) => {
        const action = {
            type: 'changeNewPaymentMethod',
            payload: {
                type: type,
                data: getPaymentMethodStructure( type )
            }
        };
        mainReducer.dispatch(action);
    }

    const changeScreenLocal = () => {
        const action = {
            type: 'changeNewPaymentMethod',
            payload: false
        };
        mainReducer.dispatch(action);
        if ((mainReducer.state.paymentMethods.length == 1) && (mainReducer.state.paymentMethods[0].id == mainReducer.state.currentPaymentMethod.id)) {
            changeScreen('collapsed');
        } else {
            changeScreen('saved');
        }
    }

    return  <div className="e__plns__opt__pay__wpr">
                    {getPaymentMethodsTypes().map( type =>
                        <Fragment key={type}>
                        <div className="e__plns__opt__pay__wpr__item">
                            <div className="e__chck" title={ getText('payment_method_title_' + type) }>
                                <input onChange={ () => handleOnChangePaymentType(type) } type="radio" id={`newPaymentMethod_${type}`} checked={ (mainReducer.state.newPaymentMethod !== false) && (mainReducer.state.newPaymentMethod.type == type) } />
                                <label htmlFor={`newPaymentMethod_${type}`}>
                                    <span className="e__chck__ico">
                                        <SVG_paymentMethod type={ type } />
                                    </span>
                                    <span className="e__chck__hd">{ getText('payment_method_title_' + type) }</span>
                                </label>
                            </div>
                            {((mainReducer.state.newPaymentMethod !== false) && (mainReducer.state.newPaymentMethod.type == type)) &&
                                <BlockSummaryPaymentExtraData type={ type } getPaymentMethodStructure={ getPaymentMethodStructure } />
                            }
                        </div>    
                        </Fragment>
                    )}           
                {((mainReducer.state.paymentMethods !== false) && (mainReducer.state.paymentMethods.length > 0)) &&
                    <button className="e__lnk" onClick={ changeScreenLocal }>{ getText('volver_nuevo_metodo_de_pago') }</button>
                }
            </div>;
}