import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { BlockFAQ } from '../blocks/BlockFAQ';
import { BlockPlans } from '../blocks/BlockPlans';
import { BlockCompare } from '../blocks/BlockCompare';
import { ModalBudget } from '../modals/ModalBudget';
import { BlockTitle } from '../blocks/BlockTitle';
import { BlockNotifications } from '../blocks/BlockNotifications';
import { ModalLicense } from '../modals/ModalLicense';
import { ModalVideo } from '../modals/ModalVideo';
import { BlockBannerCompany } from '../blocks/BlockBannerCompany';

export const ScreenPlans = () => {

    const { appReducer } = useContext( MainContext );

    return  <>
                <main className="e__plns__lst">
                    <BlockTitle title="planes" />
                    <BlockNotifications />
                    <BlockPlans />
                    <BlockCompare />
                    <BlockBannerCompany />
                    <BlockFAQ />
                    {(appReducer.state.modals.budget) && <ModalBudget />}
                    {(appReducer.state.modals.license) && <ModalLicense />}
                    {(appReducer.state.modals.video !== false) && <ModalVideo />}
                </main>
            </>;
    
}