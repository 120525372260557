export const getPaymentMethodsTypes = () => {
    return ['stripe_card','paypal'];
}

export const getPaymentMethodStructure = ( type ) => {
    switch ( type ) {
    case 'stripe_card':
        return {
            card: '',
            expires: '',
            cvc: '',
            network: ''
        }
        break;
    default:
        return false;
    }
}

export const getDataNewPaymentMethod = async( type, data, environment ) => {
    let response = '';
    switch ( type ) {
    case 'stripe_card':
        const radarSession = await getStripeRadarSession( environment );
        response = data.card.replaceAll(' ','') + ',';
        const expires = data.expires.split(' / ');
        response += expires[0] + ',';
        response += '20' + expires[1] + ',';
        response += data.cvc + ',';
        response += data.network;
        if (radarSession !== false) {
            response += ',' + radarSession;
        }
        break;
    }
    return response;
}

export const getDataCheckNewPaymentMethod = async( type, data ) => {
    let response = '';
    switch ( type ) {
    case 'stripe_card':
        response = data.replaceAll(' ','');
        break;
    }
    return response;
}

export const markErrorNewPaymentMethodFields = ( type ) => {
    let errors = [];
    switch (type) {
    case 'stripe_card':
        errors = [
            {
                section: 'payment',
                field: 'cvc',
                error: 'unknown'
            },
            {
                section: 'payment',
                field: 'card',
                error: 'unknown'
            },
            {
                section: 'payment',
                field: 'expires',
                error: 'unknown'
            }
        ];
        break;
    }
    return errors;
}

export const validateNewPaymentMethod = ( type, data ) => {
    const errors = [];
    switch (type) {
    case 'stripe_card':
        if (data.cvc == '') {
            errors.push({
                section: 'payment',
                field: 'cvc',
                error: 'empty'
            });
        } else {
            if (data.cvc.length < 3) {
                errors.push({
                    section: 'payment',
                    field: 'cvc',
                    error: 'bad'
                });               
            }
        }
        if (data.card == '') {
            errors.push({
                section: 'payment',
                field: 'card',
                error: 'empty'
            });    
        }
        if (data.expires == '') {
            errors.push({
                section: 'payment',
                field: 'expires',
                error: 'empty'
            });
        } else {
            const expires = data.expires.split(' / ');
            if ((expires.length != 2) || (expires[0].length != 2) || (expires[1].length != 2) || (expires[0] > 12)) {
                errors.push({
                    section: 'payment',
                    field: 'expires',
                    error: 'bad'
                });
            }
        }
        break;
    }
    return errors;
}

const getStripeRadarSession = async( environment ) => {
    let pk;
    if (environment == 'educaplay.com')  {
        pk = 'pk_live_51L6BqVG6FHloMrGc07pthfgXpeWBQUA0a5zHcbpgQLhYYv0cjrTigztqBLcT1finf9CQ8SYAGBvlM0hZioQaQbD900p7rc97om';
    } else {
        pk = 'pk_test_51L6BqVG6FHloMrGcSP4bSAqjVgPKnmGbAsRVscxQlvBdvquL46eOsmcyX7WjrlLtcQxR6DBcLlpmeHREFvWm0xpd00ZBlznjzs';
    }
    const stripe = Stripe( pk );
    const {radarSession, error} = await stripe.createRadarSession();
    if (error) {
        return false;
    } else {
        return radarSession.id;
    }
}