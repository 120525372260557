import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';

export const ScreenError = () => {

    const { environment, appReducer } = useContext(MainContext);
    
    return <div className="page-error">
            <section className="container-error generic-error">
                <div className="container-error__txt">
                    <div className="container-error__txt__hd">
                        <h2>Oops!</h2>
                        <p>{getText('error_' + appReducer.state.error)}</p>
                    </div>
                </div>
                <div className="container-error__img">
                    <img src={`${environment.resources}img/einstein-error-generico.webp`} title="Oops" />
                </div>
            </section>
        </div>;

}