import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { BlockPayment } from './BlockPayment';

export const BlockSummaryPaymentCollapsed = ({ changeScreen }) => {

    const { mainReducer } = useContext( MainContext );

    const changeScreenLocal = () => {
        if ((mainReducer.state.paymentMethods === false) || (mainReducer.state.paymentMethods.length == 0) || ((mainReducer.state.paymentMethods.length == 1) && (mainReducer.state.paymentMethods[0].id == mainReducer.state.currentPaymentMethod.id))) {
            changeScreen('new');
        } else {
            changeScreen('saved')
        }
    }

    return  <>  
                <div className="e__plns__opt__pay__wpr">
                    <div className="e__plns__opt__pay__info">
                        <BlockPayment payment={ mainReducer.state.currentPaymentMethod } />
                        <button className="e__lnk" onClick={ changeScreenLocal } >{ getText('cambiar') }</button>
                    </div>
                </div>
            </>;
}