import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import OutsideAlerter from '../elements/OutsideAlerter';
import { SVG_close } from '../elements/SVG';

export const ModalVideo = () => {

    const { appReducer, environment } = useContext( MainContext );

    const handleClose = () => {
        const action = {
            type: 'closeModal',
            payload: 'video'
        }
        appReducer.dispatch(action);
    }

    return  <>
            <div className="e__modal">
                <OutsideAlerter action={ handleClose }>
                <div className="e__modal__vid">
                    <div className="e__modal__vid__wpr">
                        <button className="e__btn e__btn__close" onClick={ handleClose }><SVG_close /></button>
                        <iframe src={`https://www.youtube-nocookie.com/embed/${ appReducer.state.modals.video[environment.lang] }?rel=0&amp;hl=es&amp;modestbranding=1`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
                </OutsideAlerter>
                <div className="e__modal__bckg"></div>
            </div>
            </>;

}